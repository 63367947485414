@tailwind base;
@tailwind components;
@tailwind utilities;
@import "styles/variables";
@import "styles/typography";
@import "styles/common";
@font-face {
    font-family: "cera";
    src: url("./fonts/Cera-GR-Thin.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
@font-face {
    font-family: "cera";
    src: url("./fonts/Cera-GR.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
@font-face {
    font-family: "cera";
    src: url("./fonts/Cera-GR-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "cera";
    src: url("./fonts/Cera-GR-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "cera", "sans-serif";
}
