@import "styles/variables";

.bg-primary {
    background-color: $brand-color;
}

.bg-gray {
 background-color: rgba(0, 0, 0, 0.8); 
}
.bg-rainee {
  background-color: $rainee;
}

.bg-light-green {
  background-color: $light-green-color;
}
.bg-midnight-blue {
  background-color: $midnight-blue-color;
}
.border-primary {
    border-color: $brand-color;
}
@for $i from 1 through 100 {
  .vh-#{$i} {
    height: #{$i}vh;
  }
}
@for $i from 2 through 24 {
    .br-#{$i} {
      border-radius: #{$i}px;
    }
    .br-t-r-#{$i} {
      border-top-right-radius: #{$i}px;
    }
    .br-t-l-#{$i} {
      border-top-left-radius: #{$i}px;
    }
    .br-b-r-#{$i} {
      border-bottom-right-radius: #{$i}px;
    }
    .br-b-l-#{$i} {
      border-bottom-left-radius: #{$i}px;
    }
  }

  // antd modal 
  .headstartt-antd-modal {
      .ant-modal-content{
        background: transparent;
        box-shadow: none;
      }
      transform-origin: 0 0;
  }
  .headstartt-input {
    .ant-input{
      font-size: 20px;
      background-color: transparent;
      color: black;
      &:focus{
        outline: none;
      }
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #000;
        opacity: 1; /* Firefox */
      }
    }
  }

  // antd input
  .ant-input-affix-wrapper > input.ant-input {
    background-color:transparent;
  }

 
  // ant-pagination
  .ant-pagination-prev{
    a{
      color: $brand-color;
    }
  }
  .ant-pagination-item {
    &.ant-pagination-item-active{
      background-color: $brand-color;
      border: none;
      a{
        color: white;
        &:hover{
          color: white;
        }
      }
    }
    &:hover{
      background-color: $brand-color;
      border: none;
      a {
        color: white;
        &:hover{
          color: white;
        }
      }
    }
  }



  .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding: 0;
    padding-left: 0 !important;
  }


  .review-rating {
    &.ant-rate{
      font-size: 48px;
    }
  }
  .small-rating {
    &.ant-rate{
      font-size: 12px;
    }
  }

  .slick-next:before, .slick-prev:before{
    color: $brand-color;
  }

  .activity-select{
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector{
      border-radius: 8px;
      height: 60px;
    }
  }
  .activity-select-small{
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector{
      border-radius: 9999px;
      background-color: rgba(0, 0, 0, 0.08);
      height: 100%;
      
    }
  }