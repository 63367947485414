@import "styles/variables";

@for $i from 10 through 56 {
  .fs-#{$i} {
    font-size: #{$i}px;
  }
}

@for $i from 10 through 56 {
  .lh-#{$i} {
    line-height: #{$i}px;
  }
}

.text-primary {
  color: $brand-color;
}
.text-muted {
  color: $gray-light;
}

.headstartt-input {
  .ant-input{
    font-size: 20px;
    background-color: transparent;
    color: black;
    
  }
}

