$card-dimension: 292px;
$card-dimension-long: 582px;
.event-card {
    height: $card-dimension;
    width: $card-dimension;
    object-fit: cover;
}
.host-card {
    height: $card-dimension;
    width: 100%;
    object-fit: cover;
}
.event-card-long {
    height: $card-dimension-long;
    width: 100%;
    object-fit: cover;
}
.project-card {
    height: $card-dimension;
    width: $card-dimension;
    object-fit: cover;
}