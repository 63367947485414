.cover {
    background: url('../../../../../public/cover.jpg') no-repeat center center;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #fff;
    &:before {
        content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);

      }
    
}
